import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black : black,
  white : white,
  menu: {
    dark: '#00275f',
    main: '#A2C2F0',
    light: '#C7D7EE'
  },
  primary: {
    contrastText: white,
    dark: '#00275f',
    main: '#00275e',
    light: '#00336E'
  },
  secondary: {
    contrastText: white,
    dark: '#0B3E45',
    main: '#00275f',
    light: '#DBF4F2'
  },
  sub: {
    main: '#DAF4D9',
    dark: '#00275e',
    light: '#CAE0CB'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: '#D44848',
    light: colors.red[400]
  },
  text: {
    primary: '#333333',
    secondary: '#687178',
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#FFFFFF',
    paper: white
  },
  divider: '#FFFFFF',
  border: {
    main: '#D4DDE3'
  },
};
