import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    padding: 8,
    '& .MuiIconButton-label' : {
        padding: '0px'
    }
  },
//   switchBase: {
//     color: "#DEDEDE",
//   },
  colorSecondary: {
    "&$checked": {
      color: "#FFF",
    }
  },
    track: {
        borderRadius: '30px',
        opacity: 1,
        backgroundColor: "#DEDEDE",
        // padding: 10,
        "$checked$checked + &": {
          opacity: 1,
          backgroundColor: "#7FB344"
        }
      },
      thumb: {
          width: 16,
          height: 16,
          marginTop: 2,
      },
};
