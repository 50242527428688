import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import GuestGuard from './components/GuestGuard';
import AuthGuard from './components/AuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/Login'))
  },
  {
    path: '/erp',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/erp/quotation',
        component: lazy(() => import('./views/quotation'))
      },
      {
        exact: true,
        path: '/erp/quotation/form/:id',
        component: lazy(() => import('./views/quotation/form'))
      },
      {
        exact: true,
        path: '/erp/quotation/duplicate/:id',
        component: lazy(() => import('./views/quotation/form'))
      },
      {
        exact: true,
        path: '/erp/payment',
        component: lazy(() => import('./views/payment'))
      },
      {
        exact: true,
        path: '/erp/paymentaccount',
        component: lazy(() => import('./views/paymentaccount'))
      },
      {
        exact: true,
        path: '/erp/business',
        component: lazy(() => import('./views/business'))
      },
      {
        exact: true,
        path: '/erp/overview',
        component: lazy(() => import('./views/overview'))
      },
      {
        exact: true,
        path: '/erp/customer',
        component: lazy(() => import('./views/customer'))
      },
      {
        exact: true,
        path: '/erp/customer/form/:id',
        component: lazy(() => import('./views/customer/form'))
      },
      {
        exact: true,
        path: '/erp/product',
        component: lazy(() => import('./views/product'))
      },
      {
        exact: true,
        path: '/erp/project',
        component: lazy(() => import('./views/project'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/home'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
