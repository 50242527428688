export default {
  root: {},
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)'
  },
  input: {
    padding: '10px 16px',
    fontSize: 14
  }
};
