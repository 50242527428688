import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from '../../../components/Logo';
import Account from './Account';
import Business from './Business';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
  },
  toolbar: {
    minHeight: 64,
  },
  logo: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon color="white" />
            </SvgIcon>
          </IconButton>
          {/* <Box ml={2}>
            <Logo />
          </Box> */}
        </Hidden>
        <Hidden mdDown>
          <Box>

          </Box>
        </Hidden>
        <Box className={classes.logo}>
          <Logo />
        </Box>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Business />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
