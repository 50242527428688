import React from 'react';
import {
  Box,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    background: theme.palette.primary.main
  },
  popover: {
    width: 200
  }
}));

const Business = () => {
  const classes = useStyles();
  const { user, changeBusiness } = useAuth();

  const onChange = async (value) => {
    await changeBusiness(value);
    window.location = '/';
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{ background: 'white', minWidth: '300px' }}
      >
        <Select
          variant='outlined'
          value={user?.businessId}
          fullWidth
          onChange={(e) => onChange(e.target.value)}
        >
          {(user?.business || []).map((m, i) => (
            <MenuItem key={i} value={m.orgId}>{m.orgName}</MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
}

export default Business;
