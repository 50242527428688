import { combineReducers } from '@reduxjs/toolkit';
import { reducer as quotationReducer } from '../slices/quotation';
import { reducer as productReducer } from '../slices/product';
import { reducer as customerReducer } from '../slices/customer';
import { reducer as projectReducer } from '../slices/project';
import { reducer as paymentReducer } from '../slices/payment';
import { reducer as paymentaccountReducer } from '../slices/paymentaccount';
import { reducer as businessReducer } from '../slices/business'

const rootReducer = combineReducers({
    product: productReducer,
    quotation: quotationReducer,
    payment: paymentReducer,
    paymentaccount: paymentaccountReducer,
    customer: customerReducer,
    project: projectReducer,
    business: businessReducer
});

export default rootReducer;
