export default {
  root: {},
  outlined: {
    fontSize: 14,
    transform: 'translate(14px, 16px) scale(1)',
  },
  formControl: {
    fontSize: 14,
    transform: 'translate(14px, 16px) scale(1)',
  },
  shrink: {
    transform: 'translate(14px, -6px) scale(0.75)',
  }
};
