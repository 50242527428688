export default {
    root: {},
    select: {
        fontSize: 14,
    },
    selectMenu: {
        minHeight: '1.5rem',
        padding: '10px 16px',
    }
  };
  