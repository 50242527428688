import palette from '../palette';
import typography from '../typography';

export default {
  root: {

  },
  body1: {
      ...typography.body1
  }
};
