import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../utils/api';
import encodeParams from '../utils/queryParam'

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
};

const slice = createSlice({
  name: 'paymentaccount',
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPending(state, action) {
      state.isLoading = true;
    },
  }
});

export const reducer = slice.reducer;

export const getList = (parameter,orgId,businessId) => async (dispatch) => {
  // dispatch(slice.actions.setPending());
  const url = `${API.URL_PAYMENT_ACCOUNT}/${orgId}/action/GetPaymentAccountListWithPaging/${businessId}?${encodeParams(parameter)}`
  const response = await axios.get(url);
  dispatch(slice.actions.getList(response.data.data));
};

export default slice;
