import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from '../components/SplashScreen';
import axios from 'axios';
import API from '../utils/api';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem('token', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'CHANGE': {
      const { businessId } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          businessId
        }
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  changeBusiness: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (username, password) => {
    const id = "default";
    const response = await axios.post(`${API.URL_AUTHORIZATION}/${id}/action/SignIn`, { username, password });
    if (response?.data?.status?.message !== 'Success') {
      throw new Error('ชื่อผู้เข้าใช้ / รหัสผ่านไม่ถูกต้อง'); 
    }
    
    const token = response?.data?.data?.token;
    setSession(token);

    const info = await axios.get(`${API.URL_AUTHORIZATION}/${id}/action/GetUserProfile`);
    const business = await axios.get(`${API.URL_ORGANIZATION}/${info?.data?.data?.orgCustomId}/action/GetBusiness`);

    const businessId = (business.data?.data || [])?.[0]?.orgId;
    sessionStorage.setItem('businessId', businessId);

    const role = await axios.get(`${API.URL_AUTHORIZATION}/${info?.data?.data?.orgCustomId}/action/GetUserRole/${businessId}`);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          data: {
            ...info.data?.data,
          },
          business: business.data?.data || [],
          businessId: businessId,
          role: role.data?.data?.role || []
        },
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.sessionStorage.setItem('token', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const changeBusiness = (value) => {
    sessionStorage.setItem("businessId", value)
    dispatch({ type: 'CHANGE', payload: { businessId: value } });
  };


  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.sessionStorage.getItem('token');

        if (accessToken) {
          setSession(accessToken);
         
          const id = "default";
          const info = await axios.get(`${API.URL_AUTHORIZATION}/${id}/action/GetUserProfile`);
          if (info?.data?.status?.message !== 'Success') {
            throw new Error('info error'); 
          }
          
          const business = await axios.get(`${API.URL_ORGANIZATION}/${info?.data?.data?.orgCustomId}/action/GetBusiness`);
          if (business?.data?.status?.message !== 'Success') {
            throw new Error('info error'); 
          }

          const role = await axios.get(`${API.URL_AUTHORIZATION}/${info?.data?.data?.orgCustomId}/action/GetUserRole/${sessionStorage.getItem("businessId")}`);
 
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {
                data: {
                  ...info.data?.data,
                },
                business: business.data?.data || [],
                businessId: sessionStorage.getItem("businessId"),
                role: role.data?.data?.role || []
              },
            }
          });
        } else {
          setSession(null);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        setSession(null);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        changeBusiness
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;