import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../utils/api';
import encodeParams from '../utils/queryParam'

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      // state.data = {
      //   currentPage: 1,
      //   hasNext: false,
      //   hasPrevious: false,
      //   pageSize: 10,
      //   totalCount: 1,
      //   totalPages: 1,
      //   items: [
      //       {
      //           index: '1',
      //           projectName: 'โครงการ 1',
      //           recordStatus: 'ปกติ'
      //         }
      //   ]
      // }
    },
    setPending(state, action) {
      state.isLoading = true;
    },
  }
});

export const reducer = slice.reducer;

export const getList = (parameter,orgId,businessId) => async (dispatch) => {
    const url = `${API.URL_PROJECT}/${orgId}/action/GetProjectListWithPaging/${businessId}?${encodeParams(parameter)}`
    const response = await axios.get(url);
    dispatch(slice.actions.getList(response.data.data));
};

export const createProject = async (orgId, data) => {
  try {
    const url = `${API.URL_PROJECT}/${orgId}/action/CreateProject`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export const updateProject = async (orgId, businessId, id, data) => {
  try {
    const url = `${API.URL_PROJECT}/${orgId}/action/UpdateProject/${businessId}/${id}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export default slice;
