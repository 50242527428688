import React, { useEffect,useRef,useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    background: theme.palette.primary.main
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { logout } = useAuth();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [history.location])

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      window.location = '/login';
      // window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/signin`;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src="/static/images/avatar.png"
        />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        style={{ padding: 0 }}
      >
        <MenuItem onClick={handleLogout}>
          ออกจากระบบ
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
